.card.user-review, .card.reviews {
  --minPad: 14px;

  padding-top: var(--minPad);
  padding-bottom: var(--minPad);
}

.card.user-review .footer { margin-bottom: calc(var(--minPad) * -1); }

.card.edit-review {
  will-change: transform, opacity;
  transform-origin: top;
  animation: slideDown .4s ease-in-out;
  max-height: 2000px;
  z-index: 10;
}
