.snackbar {
  --distance: 24px;

  position: fixed;
  bottom: var(--distance);
  right: var(--distance);
  left: var(--distance);
  display: block;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 16px;
  width: fit-content;
  max-width: calc(100% - (var(--distance) * 2));
  color: #222;
  font-size: .87rem;
  min-height: 44px;
  border-radius: 4px;
  background-color: rgb(var(--accentClr));
  text-decoration: none;
  box-shadow: 0 3px 5px -1px rgba(var(--blackClr), .2), 0 6px 10px 0 rgba(var(--blackClr), .14), 0 1px 18px 0 rgba(var(--blackClr), .12);
  animation: fadeInSlideUp .3s cubic-bezier(.21, .61, .35, 1) both .5s;
  z-index: 999;
  &:hover { color: #222; }
  & .snackbar-message {
    display: block;
    padding: 8px 0;
  }
  & .snackbar-action {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -8px;
    padding-left: 16px;
    & .btn.flat {
      color: inherit;
      font-weight: 600;
    }
  }
}

/* SNACKBAR CONTENT */
.snackbar-content {
  background-color: rgba(var(--cardClr), .9);
  backdrop-filter: saturate(180%) blur(10px);
  overflow: hidden;
  &.error { background-color: rgba(var(--errorClr), .9); }
  &.info { background-color: rgba(var(--primaryClr), .9); }
  &.success { background-color: rgba(var(--successClr), .9); }
  &.warning { background-color: rgba(var(--accentClr), .9); }
  & .btn.flat { color: white; }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    align-self: flex-start;
    background-color: rgba(255, 255, 255, .3);
    transform-origin: left bottom;
    transform: scaleX(0);
    will-change: transform;
    animation-name: progress;
    animation-timing-function: linear;
    animation-duration: inherit;
    animation-play-state: inherit;
  }
}

@keyframes progress {
  99% {
    border-bottom-right-radius: 0;
  }

  100% {
    transform: scaleX(1);
    border-bottom-right-radius: inherit;
  }
}
