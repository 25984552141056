.card.author {
  & .avatar {
    width: var(--avatarSize);
    height: var(--avatarSize);
    line-height: var(--avatarSize);
    box-shadow: inset 1px 1px 1px rgba(var(--blackClr), .05);
  }
  & .title { line-height: var(--avatarSize); }
  & .info-row.bio { margin-bottom: 1em; }
  & .loader { display: none; }
}

#authorComponent {
  & .randomquote { --pad: 10px; }
  & .blockquote {
    /* margin-top: 10px; */
    overflow: hidden;
    max-height: 0;
    animation-name: maxheightIn;
    animation-duration: 1.2s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
  }
  & .coverview .book .info .author { display: none; }
}
