.card.discussions {
  --minPad: 14px;

  padding-top: var(--minPad);
  padding-bottom: var(--minPad);
}

.discussion {
  --minPad: 14px;
  --avatarSize: 32px;
  --bookWidth: 40px;

  border-radius: var(--radius);
  &.flagged {
    background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, rgba(var(--redClr), .08) 10px, rgba(var(--redClr), .08) 20px);
    background-repeat: no-repeat;
    margin-left: calc(var(--pad) * -1);
    margin-right: calc(var(--pad) * -1);
    margin-bottom: calc(var(--minPad) * -1);
    padding-left: var(--pad);
    padding-right: var(--pad);
    padding-bottom: var(--minPad);
  }
  & + .discussion {
    margin-top: var(--minPad);
    padding-top: var(--minPad);
    border-top: 2px solid rgba(var(--blackClr), .05);
  }
  & .left {
    position: relative;
    padding-right: 0;
  }
  & .left .avatar.absolute {
    --avatarSize: 28px;

    position: absolute;
    top: 3px;
    right: -9px;
    border: 3px solid rgb(var(--cardClr));
    background-color: #BDBDBD;
    font-size: .7rem;
    will-change: opacity, transform;
    transition: all .2s ease-in-out;
  }
  & .left .avatar {
    width: var(--avatarSize);
    height: var(--avatarSize);
    line-height: var(--avatarSize);
    font-size: .8rem;
  }
  & .hoverable-items:hover .avatar.absolute {
    opacity: 0;
    transform: scale(0);
    user-select: none;
  }
  & .right { padding-left: var(--minPad); }
  & .head {
    line-height: var(--avatarSize);
    & .author { max-width: calc(100% - 45px); }
    & h3, & h4 {
      margin: 0 auto;
      color: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transition: all .2s ease-in-out;
      & .date {
        font-size: .82rem;
        color: var(--lighterTextClr);
        margin-left: 10px;
        font-weight: 200;
      }
    }
  }
  & .title {
    color: rgb(var(--contrastedTextClr));
    margin-top: .2rem;
  }
  & .rating {
    line-height: inherit;
    animation: none;
    & .rating-num { margin-left: 7px; }
    & .ratings-num { display: none; }
  }
  & .text {
    font-weight: 300;
    margin-top: .2rem;
    white-space: pre-line;
    word-wrap: break-word;
  }
}

.discussions {
  & .discussion {
    will-change: opacity, transform;
    animation: fadeIn .2s ease-in-out;
  }
  & .head {
    & h2 { margin-bottom: 1rem; }
    & .counter {
      line-height: inherit;
      font-size: .82rem;
      color: var(--lighterTextClr);
      margin-left: 10px;
      font-weight: 200;
    }
  }
}
