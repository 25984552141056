.emoji-mart {
  animation: fadeInSlideDown .6s cubic-bezier(.21, .61, .35, 1) both;
  will-change: opacity, transform;
  box-shadow: 0 6px 12px rgba(var(--blackClr), .2);
  border-color: rgba(var(--blackClr), .1);
  opacity: .96;
  max-width: 100%;
}

.emoji-mart-search input {
  border-radius: 99px;
}

.emoji-mart-anchor-bar {
  background-color: rgb(var(--primaryClr));
}

.dark.emoji-mart {
  background-color: rgb(var(--canvasLightClr));
  & .emoji-mart-category-label span {
    background-color: rgb(var(--canvasLightClr));
    color: #FFF;
  }
  & .emoji-mart-search input {
    background-color: rgb(var(--canvasClr));
    border-color: rgba(var(--blackClr), .1);
    color: #FFF;
  }
}
