.skltns-row { display: block; }
.skltn {
  &.shelf-row, &.stacked {
    position: relative;
    height: var(--shelf-rowHeight);
    overflow: hidden;
  }
  &.area, &.shelf-row, &.rows, &.review, &.comment, &.quote {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .6;
      background: linear-gradient(90deg, transparent, rgb(var(--cardClr)), transparent) calc(-1 * var(--coverWidth)) 0;
      background-size: 150px 100%;
      background-repeat: no-repeat;
      background-position: -150px 0;
      animation: skltnShelfRow 2.5s infinite linear;
      z-index: 1;
    }
  }
  &.area {
    position: relative;
    display: block;
    background: var(--skltnClr);
    border-radius: 2px;
    margin: auto;
    height: inherit;
  }
  &.book {
    min-width: var(--bookWidth);
    margin: 0 auto;
    width: 100%;
    height: var(--shelf-rowHeight);
    background:
      linear-gradient(var(--skltnClr), var(--skltnClr)) var(--skltnCoverX) top,
      linear-gradient(var(--skltnClr), var(--skltnClr)) var(--skltnTitleX) var(--skltnTitleY),
      linear-gradient(var(--skltnClr), var(--skltnClr)) var(--skltnTitleX) var(--skltnAuthorY);
    background-size: var(--coverWidth) var(--coverHeight), var(--bookWidth) 12px, calc(var(--coverWidth) * .8) 11px;
    background-repeat: no-repeat;
  }
  &.stacked {
    --bookWidth: 100px;
    --coverWidth: 80px;
    --coverHeight: 120px;
    --shelf-rowHeight: var(--coverHeight);
    --skltnTitleY: 10px;
    --skltnAuthorY: 30px;
    --skltnCoverX: 0;
    --skltnTitleX: 100px;

    height: calc(3 * (var(--shelf-rowHeight) + 15px));
  }
  &.bubble {
    --skltnTitleY: calc(var(--avatarSize) + 12px);
    --coverWidth: calc(var(--avatarSize) + 20px);

    /* min-width: var(--bookWidth); */
    margin: 0 auto;
    width: 100%;
    height: var(--shelf-rowHeight);
    border-radius: 0;
    background-image:
      radial-gradient(circle var(--avatarSize), var(--skltnClr) 50%, transparent 0),
      linear-gradient(var(--skltnClr), var(--skltnClr));
    background-size: var(--avatarSize) var(--avatarSize), var(--coverWidth) 12px;
    background-position: var(--skltnCoverX) top, var(--skltnTitleX) var(--skltnTitleY);
    background-repeat: no-repeat;
  }
  &.rows, &.review, &.comment, &.quote { margin-bottom: 10px; }
  &.rows, &.review, &.comment, &.quote, &.notification, &.avatar-row, &.dash {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    border-color: var(--skltnClr);
  }
  &.rows {
    --height: 99px;
    --rowH: 22px;

    height: var(--height);
    background-image:
      repeating-linear-gradient(var(--skltnClr), var(--skltnClr) calc(var(--rowH) / 2), transparent calc(var(--rowH) / 2), transparent var(--rowH)),
      linear-gradient(var(--skltnClr) 50%, transparent 50%);
    background-position: 0 0, 0 calc(var(--height) - (var(--rowH) / 2));
    background-size: 100% calc(var(--height) - var(--rowH)), calc(100% - var(--height)) var(--rowH);
    &.one { --height: var(--rowH); }
    &.two { --height: 33px; }
    &.three { --height: 55px; }
    &.four { --height: 77px; }
  }
  &.review, &.comment {
    --padV: 10px;
    --textH: 14px;
    --padTextH: calc(var(--avatarSize) + var(--minPad));
    --height: calc(calc(var(--padV) * 2) + var(--avatarSize) + (var(--textH) * 4));

    height: var(--height);
    background-image:
      radial-gradient(circle calc(var(--avatarSize) / 2), var(--skltnClr) 99%, transparent 0),
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      repeating-linear-gradient(var(--skltnClr), var(--skltnClr) 11px, transparent 11px, transparent 22px),
      linear-gradient(var(--skltnClr) 50%, transparent 50%);
    background-position:
      0 var(--padV),
      var(--padTextH) calc(var(--padV) + calc((var(--avatarSize) - var(--textH)) / 2)),
      var(--padTextH) calc(var(--avatarSize) + calc(var(--padV) * 2)),
      var(--padTextH) calc(calc(var(--padV) * 2) + var(--avatarSize) + (var(--textH) * 3));
    background-size:
      var(--avatarSize) var(--avatarSize),
      200px calc(var(--padV) + var(--textH)),
      100% calc(var(--textH) * 3),
      calc(100% - 100px) 22px;
  }
  &.quote {
    height: 124px;
    background-image:
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      repeating-linear-gradient(var(--skltnClr), var(--skltnClr) 11px, transparent 11px, transparent 22px),
      linear-gradient(var(--skltnClr) 50%, transparent 50%);
    background-position: 0 0, 54px 0, 54px 110px;
    background-size: 40px 123px, 100% 110px, calc(100% - 150px) 22px;
  }
  &.notification {
    --avatarSize: 24px;
    --padH: 7px;
    --padV: 10px;
    --height: calc((var(--padV) * 2) + var(--avatarSize));
    --rowH: calc(var(--height) + 1px);
    --textH: 22px;
    --padTextH: calc(var(--padH) + var(--avatarSize) + (var(--padH) * 2));
    --padTextV: calc(((var(--height) - var(--textH)) / 2) + 4px);

    height: var(--rowH);
    background-image:
      radial-gradient(circle calc(var(--avatarSize) / 2), var(--skltnClr) 99%, transparent 0),
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      linear-gradient(var(--skltnLightClr) 50%, transparent 50%);
    background-position:
      var(--padH) var(--padV),
      var(--padTextH) var(--padTextV),
      100% var(--padTextV),
      0 var(--height);
    background-size:
      var(--avatarSize) var(--avatarSize),
      170px var(--textH),
      var(--rowH) var(--textH),
      100% 1px;
  }
  &.avatar-row {
    --avatarSize: 40px;
    --padH: 7px;
    --padV: calc(7px + .4rem);
    --height: calc((var(--padV) * 2) + var(--avatarSize) + 1px);
    --textH: 22px;
    --padTextV: calc(((var(--height) - var(--textH)) / 2) + 4px);

    height: var(--height);
    background-image:
      radial-gradient(circle calc(var(--avatarSize) / 2), var(--skltnClr) 99%, transparent 0),
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      linear-gradient(var(--skltnClr) 50%, transparent 50%);
    background-position:
      0 var(--padV),
      calc(var(--avatarSize) + (var(--padH) * 2)) var(--padTextV),
      100% var(--padTextV);
    background-size: var(--avatarSize) var(--avatarSize), 170px var(--textH), var(--height) var(--textH);
  }
  &.dash {
    height: 35px;
    background-image:
      radial-gradient(circle 10px, var(--skltnClr) 99%, transparent 0),
      linear-gradient(var(--skltnClr) 50%, transparent 50%),
      linear-gradient(var(--skltnClr) 50%, transparent 50%);
    background-position: 0 7px, 34px 11px, 100% 11px;
    background-size: 20px 20px, 100px 22px, 35px 22px;
  }
}
/* .stacked .skltn.book { background-repeat: repeat-y; } */
h2, h3, h4 {
  & .skltn.area {
    margin-left: 0;
    width: 40%;
    min-width: 50px;
    max-width: 250px;
  }
}
h1 .skltn.area { height: 38px; }
h2 .skltn.area { height: 28px; }
h3 .skltn.area { height: 16px; }
h4 .skltn.area { height: 14px; }

.bubbles .skltn.shelf-row {
  --shelf-rowHeight: 119px;

  grid-template-columns: repeat(var(--bubblesPerRow-lg), 1fr);
}
