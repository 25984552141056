@import url('media-queries.css');

.select-isbn {
  -webkit-appearance: none;
  border-color: transparent;
  border-radius: var(--radius);
  background-color: transparent;
  padding: 2px 5px;
  margin-left: -6px;
  cursor: pointer;
  color: var(--mainTextClr);
  &:hover { border-color: rgba(var(--blackClr), .1); }
}

.book-profile {
  & .title, & .subtitle {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    line-height: 130%;
  }
  & .title {
    font-size: 2.2rem;
    &.flex { align-items: center; }
    & .mention {
      display: flex;
      opacity: 0;
      margin-left: .5rem;
    }
    &:hover .mention { opacity: 1; }
  }
  & .subtitle {
    font-size: 1.35rem;
    color: var(--lightTextClr);
  }
  & .rating {
    height: var(--btnHeight);
    line-height: var(--btnHeight);
  }
  & .description {
    margin-top: .65em;
    margin-bottom: .65em;
    white-space: pre-line;
    word-wrap: break-word;
  }
  &.dark {
    & .title, & .subtitle { font-weight: 300; }
  }
}

@media (--to-l) {
  #bookProfile {
    & .share-buttons {
      position: absolute; top: 0; left: 0;
      & .btn-share-container {
        display: block;
        margin: 0 .4em .4em;
      }
    }
    & .book-profile {
      & .title .mention { display: none; }
      & .rating {
        display: block !important;
        /* & .react-rater { font-size: 1.4rem; } */
      }
    }
  }
}

#bookProfile {
  position: relative;
  z-index: 1;
  & .card {
    &.main { min-height: 256px; }
    & .book { margin-bottom: 10px; }
  }
  /* & .own.review {
    &:hover .row { opacity: 1; }
    & .row { opacity: .3; transition: all .4s ease-in-out; }
  } */
  & .book-profile .rating {
    display: flex;
    align-items: center;
    & .react-rater { font-size: 1.4rem; }
  }
}

@media (--to-s) {
  #bookProfile .book-profile {
    & .title .mention { display: none; }
    & .author {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 105px);
      display: inline-block;
      vertical-align: middle;
    }
    & .user.rating {
      & .react-rater {
        font-size: 2.3rem;
        line-height: inherit;
      }
      & .label { display: none; }
    }
  }
}

@media (pointer: coarse) {
  #bookProfile .book-profile .user.rating {
    & .react-rater { font-size: 2.3rem; }
    & .label { display: none; }
  }
}
