.search-book {
  & .form-group {
    & input { flex: 1; }
    & .message { margin-top: 8px; }
  }
  & .loader {
    bottom: 0;
    padding-top: 30px;
    margin-top: -10px;
    height: auto;
  }
}
