@import url('media-queries.css');
@import url('react-medium-image-zoom/dist/styles.css');

:root {
  color-scheme: dark light;

  /* Colors */
  /* http://www.material-ui.com/#/customization/colors */
  --primaryLighterClr: 0, 188, 212;       /* cyan500 */
  --primaryLightClr: 0, 172, 193;         /* cyan600 */
  --primaryClr: 0, 151, 167;              /* cyan700 */
  --primaryDarkClr: 0, 131, 143;          /* cyan800 */
  --accentLightClr: 255, 213, 79;         /* amber300 */
  --accentClr: 255, 202, 40;              /* amber400 */
  --accentDarkClr: 255, 193, 7;           /* amber500 */
  --secondaryClr: 236, 64, 122;           /* pink400 */
  --greenLightClr: 76, 175, 80;           /* green500 */
  --greenClr: 67, 160, 71;                /* green600 */
  --greenDarkClr: 56, 142, 60;            /* green700 */
  --redLightClr: 239, 83, 80;             /* red400 */
  --redClr: 244, 67, 54;                  /* red500 */
  --redDarkClr: 229, 57, 53;              /* red600 */
  --orangeLightClr: 255, 167, 38;         /* orange400 */
  --orangeClr: 255, 152, 0;               /* orange500 */
  --orangeDarkClr: 251, 140, 0;           /* orange600 */
  --deepPurpleLightClr: 125, 86, 194;     /* deeppurple400 */
  --deepPurpleClr: 103, 58, 183;          /* deeppurple500 */
  --deepPurpleDarkClr: 94, 53, 177;       /* deeppurple600 */
  --purpleLightClr: 171, 71, 188;         /* purple400 */
  --purpleClr: 156, 39, 176;              /* purple500 */
  --purpleDarkClr: 142, 36, 170;          /* purple600 */

  /* LIGHT THEME */
  --lightGrey90: 144, 164, 174;
  --lightGrey80: 176, 190, 197;
  --lightGrey70: 207, 216, 220;
  --lightGrey60: 236, 239, 241;

  /* DEFAULT DARK THEME */
  --blueGrey90: 38, 50, 56;
  --blueGrey80: 50, 67, 78;
  --blueGrey70: 64, 86, 99;
  --blueGrey60: 78, 105, 120;

  /* CUSTOM DARK THEME */
  --darkBlueGrey90: 27, 37, 42;
  --darkBlueGrey80: 35, 45, 50;
  --darkBlueGrey70: 43, 53, 58;
  --darkBlueGrey60: 50, 60, 65;
  --darkBlueGrey50: 55, 65, 70;
  --darkBlueGrey40: 60, 70, 75;

  /* CHROME DARK THEME */
  --darkGrey90: 32, 33, 36;
  --darkGrey80: 41, 42, 45;
  --darkGrey70: 53, 54, 58;
  --darkGrey60: 60, 64, 67;
  --darkGrey50: 70, 74, 76;
  --darkGrey40: 80, 84, 86;

  /* Semantics */
  --blackClr: 0, 0, 0;
  --whiteClr: 255, 255, 255;
  --successClr: var(--greenClr);
  --errorClr: var(--redClr);
  --alertClr: var(--orangeClr);
  --canvasClr: var(--blueGrey90);
  --canvasLightClr: var(--blueGrey80);
  --canvasLighterClr: var(--blueGrey70);
  --canvasLightestClr: var(--blueGrey60);
  --mainClr: var(--canvasClr);
  --cardClr: var(--canvasLightClr);
  --bookClr: var(--canvasLighterClr);
  --linkClr: var(--primaryLightClr);
  --linkClrHover: var(--primaryLighterClr);
  --linkClrActive: var(--primaryClr);
  --contrastedTextClr: var(--blackClr);
  --cardDarkBg: rgba(var(--blackClr), .2);
  --cardDarkerBg: rgba(var(--blackClr), .3);
  --cardDarkestBg: rgba(var(--blackClr), .4);
  --skltn: 0, 10, 20; /* this should be --skltnClr */
  --skltnClr: rgba(var(--skltn), .15); /* this should be something like --skltnRgb */
  --skltnLightClr: rgba(var(--skltn), .04);
  --disabledClr: rgba(var(--blackClr), .2);
  --btnDisabledBg: rgba(var(--blackClr), .07);
  --mainTextClr: #212529;
  --lightTextClr: #AAA;
  --lighterTextClr: #CCC;
  --lightestTextClr: #E9E9E9;
  --ultraLightestTextClr: #ECECEC;

  /* Measures */
  --radius: 8px;
  --radius-sm: 4px;
  --headerHeight: 60px;
  --btnHeight: 36px;
  --btnPad: .8rem;
  --btnFontSize: .9rem;
  --pad: 24px;
  --avatarSize: 40px;
  --shelf-rowHeight: 224px;
  --bookWidth: 140px;
  --coverWidth: 120px;
  --coverHeight: 180px;
  --skltnTitleY: 190px;
  --skltnAuthorY: 209px;
  --skltnStarsY: 228px;

  /* Values */
  --booksPerRow-lg: 7;
  --bubblesPerRow-lg: 9;

  /* Alignments */
  --skltnCoverX: center;
  --skltnTitleX: center;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  appearance: none;
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: rgb(var(--canvasLightClr));
  transition: all .2s ease-in-out;
  &:hover {
    background-color: rgb(var(--canvasLighterClr));
  }
}

/* THEMES */
.light, .light-theme, .light-theme .dark:not(.force-theme) {
  color-scheme: light;
  --canvasClr: var(--lightGrey90);
  --canvasLightClr: var(--lightGrey80);
  --canvasLighterClr: var(--lightGrey70);
  --canvasLightestClr: var(--lightGrey60);
  --mainClr: var(--canvasLighterClr);
  --cardClr: var(--whiteClr);
  --bookClr: var(--canvasLighterClr);
  --contrastedTextClr: var(--blackClr);
  --mainTextClr: #212529;
  --lightTextClr: #AAA;
  --lighterTextClr: #CCC;
  --lightestTextClr: #E9E9E9;
  --ultraLightestTextClr: #ECECEC;
  --linkClr: var(--primaryClr);
  --linkClrHover: var(--primaryLightClr);
  --linkClrActive: var(--primaryDarkClr);
  --skltnClr: rgba(var(--skltn), .06);
}

.dark, .mixed-theme, .dark-theme, .dark-theme .light:not(.force-theme) {
  color-scheme: dark;
  --canvasClr: var(--blueGrey90);
  --canvasLightClr: var(--blueGrey80);
  --canvasLighterClr: var(--blueGrey70);
  --canvasLightestClr: var(--blueGrey60);
  --mainClr: var(--canvasClr);
  --cardClr: var(--canvasLightClr);
  --bookClr: var(--canvasLighterClr);
  --contrastedTextClr: var(--whiteClr);
  --cardDarkBg: rgba(var(--blackClr), .2);
  --cardDarkerBg: rgba(var(--blackClr), .3);
  --cardDarkestBg: rgba(var(--blackClr), .4);
  --mainTextClr: rgba(230, 250, 255, .7);
  --lightTextClr: rgba(230, 250, 255, .5);
  --lighterTextClr: rgba(230, 250, 255, .3);
  --lightestTextClr: rgba(230, 250, 255, .15);
  --ultraLightestTextClr: rgba(230, 250, 255, .05);
  --linkClr: var(--primaryLightClr);
  --linkClrHover: var(--primaryLighterClr);
  --linkClrActive: var(--primaryClr);
  --skltnClr: rgba(var(--skltn), .15);
}

.primary {
  --skltnClr: rgba(var(--skltn), .15);
  --contrastedTextClr: var(--whiteClr);
  --mainTextClr: rgba(230, 250, 255, .7);
  --lightTextClr: rgba(230, 250, 255, .5);
  --lighterTextClr: rgba(230, 250, 255, .3);
  --lightestTextClr: rgba(230, 250, 255, .15);
  --ultraLightestTextClr: rgba(230, 250, 255, .05);
  --linkClr: var(--whiteClr);
  --linkClrHover: var(--whiteClr);
  --linkClrActive: var(--whiteClr);
  --btnDisabledBg: rgb(var(--primaryLightClr));
  --cardClr: var(--primaryClr);
  --successClr: 87, 206, 92;
  --errorClr: 171, 55, 46;
}

.dark, .primary {
  & .react-autosuggest__suggestions-container {
    background-color: white;
  }
}

[class^='MuiPaper'] {
  --contrastedTextClr: var(--blackClr);
  --mainTextClr: #212529;
  --lightTextClr: #AAA;
  --lighterTextClr: #CCC;
  --lightestTextClr: #E9E9E9;
  --ultraLightestTextClr: #ECECEC;
  --linkClr: var(--primaryClr);
  --linkClrHover: var(--primaryLightClr);
  --linkClrActive: var(--primaryDarkClr);
  --skltnClr: rgba(var(--skltn), .06);
}

/* MAIN STYLE */
html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

*, *::before, *::after { box-sizing: inherit; }
*:focus { outline: none; }

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  background: rgb(var(--mainClr));
  color: var(--mainTextClr);
  font-family: Roboto, sans-serif;
  font-size: .9rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  padding-bottom: 4rem;
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .45em;
  margin-bottom: .45em;
  font-weight: 400;
  color: rgb(var(--primaryLightClr));
}
p {
  margin-top: 0;
  margin-bottom: .45em;
  &:last-child { margin-bottom: 0; }
}
p, ul, ol, .dialog.light { color: var(--mainTextClr); }

ul.nolist {
  list-style-image: none;
  margin: 0;
  padding: 0;
  & li { display: block; }
}

.container-divider {
  height: 70px;
  width: 100%;
  background-size: auto 70px;
  background-position: center;
  background-repeat: repeat-x;
}

.info-row {
  margin-bottom: .25em;
  &:last-child {
    margin-bottom: 0;
    & .btn { margin-bottom: 0; }
  }
  &.centered { text-align: center; }
}
.card .info-row.footer { display: block; }
/* .info-row .btn { margin-top: 0; margin-bottom: 0; } */

.shelfdetails, .bookdetails {
  --lighterTextClr: var(--lightestTextClr);

  color: var(--lightTextClr);
  font-weight: 200;
}

/* AVATAR */
.avatar {
  &.prepend-input {
    --avatarSize: 46px;

    margin-top: 16px;
    width: var(--avatarSize);
    height: var(--avatarSize);
  }
  &.thumb {
    width: 100%;
    min-height: 100%;
    border-radius: 99px;
  }
}

.avatar-badge {
  & span {
    top: 10px;
    right: 10px;
    z-index: 2;
  }
}

.card .avatar {
  background-color: rgba(var(--blackClr), .1);
  color: rgba(var(--blackClr), .3);
}

.table li, .avatar-row {
  --pad: 7px;
  --avatarSize: 40px;
  --marginBottom: .8rem;

  border-width: 0;
  border-style: solid;
  border-color: rgba(var(--blackClr), .1);
  line-height: var(--avatarSize);
  margin-bottom: var(--marginBottom);
  & .avatar svg { --iconSize: 12px; }
  & .btns { overflow: visible; }
  & .btn { margin-top: 0; margin-bottom: 0; }
  & .tip { display: none !important; }
  & > a {
    border-radius: var(--radius);
    transition: all .2s ease-out;
    &:hover { background-color: rgba(var(--primaryClr), .07); }
    &:active { background-color: rgba(var(--primaryClr), .1); }
  }
  & [class^='col']:not(.btns) {
    height: var(--avatarSize);
    line-height: var(--avatarSize);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    & svg { --iconSize: 15px; }
  }
}
.avatar-row .btns { height: var(--avatarSize); }
.dense li {
  --pad: 7px;
  --avatarSize: 20px;
  --marginBottom: .4rem;

  &.empty {
    margin-top: var(--marginBottom);
  }
}
.dense .avatar-row .avatar { font-size: .55rem; }
.table {
  &.bordered {
    border-radius: var(--radius);
    border: 1px solid var(--lighterTextClr);
    & .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  & li {
    position: relative;
    &:not(:last-child) {
      --marginBottom: 0;

      border-bottom: 1px solid var(--ultraLightestTextClr);
    }
    &.labels {
      color: var(--lightTextClr);
      font-size: .76rem;
      user-select: none;
      border-bottom: 1px solid var(--lightestTextClr);
    }
  }
  & .locked::after, & .deleted::after {
    --size: 4px;

    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -14px;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    margin: auto;
    animation: fadeIn .2s ease-out;
  }
  & .locked::after { background-color: rgb(var(--alertClr)); }
  & .deleted::after { background-color: rgb(var(--errorClr)); }
  & .row, & li .row { border-radius: 0; transition: all .1s ease-in; }
  & .row {
    &:hover { background-color: rgba(var(--blackClr), .04); }
    & .row { background-color: transparent; padding: 0; }
  }
  & .labels .row { background-color: transparent; }
}

.table li .row, .avatar-row > .row {
  position: relative;
  overflow: hidden;
  padding: var(--pad) 0;
  border-radius: var(--radius);
}
.table li .avatar-container { overflow: visible; }
.avatar-row .ripple::before { left: 10%; }
.avatar-row .avatar {
  /* display: inline-flex */
  display: flex;
  align-items: center;
  justify-content: center;
  /* vertical-align: middle; */
  width: var(--avatarSize);
  height: var(--avatarSize);
  user-select: none;
}
.absolute-row {
  background-color: rgb(var(--cardClr));
  position: absolute;
  padding-left: var(--pad);
  padding-right: var(--pad);
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;
  transition: all .16s ease-out;
  &.left { left: 0; transform: translateX(-8px); transform-origin: left; }
  &.right { right: 0; transform: translateX(8px); transform-origin: right; }
}
.row:hover .absolute-row {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}

.dash-table {
  max-height: 566px;
  overflow: hidden auto;
}

.table li.read { color: var(--lighterTextClr); }

.expandible-parent {
  cursor: pointer;
  &.compressed .expandible { height: 0; max-height: 0; }
  &.expanded {
    & .expandible { height: auto; max-height: 999px; }
    & .expandible-icon svg { transform: rotate(180deg); }
  }
  & .expandible { overflow: hidden; transition: max-height .9s ease-in-out; padding-left: 20px; }
  & .expandible-icon svg { transition: all .2s ease-in-out; }
}

a [role='menuitem'] { color: inherit !important; }

.link {
  display: inline;
  appearance: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  &:disabled {
    &, &:hover {
      color: var(--disabledClr);
    }
  }
}

a, .link {
  color: rgb(var(--linkClr));
  text-decoration: none;
  font-size: inherit;
  font-weight: 400;
  outline: none;
  transition: all .2s ease-in-out;
  &:hover { color: rgb(var(--linkClrHover)); }
  &:active { color: rgb(var(--linkClrActive)); }
  &.active { color: rgb(var(--accentClr)); }
}

svg { vertical-align: middle; color: currentcolor; }
.counter svg, .btn svg { vertical-align: text-bottom; }
.MuiMenuItem-root .MuiListItemIcon-root { min-width: 32px; }
.material-icons svg.svg-icon { --iconSize: 24px; vertical-align: text-top; }
svg.svg-icon, .btn svg { --iconSize: 18px; height: var(--iconSize); width: var(--iconSize); transition: all .2s ease-in-out; }

hr.line {
  margin: 30px auto;
  border: 0;
  height: 2px;
  background-color: rgba(var(--blackClr), .07);
}

.divider {
  height: 0;
  border: 0;
  border-top: 2px solid rgba(0, 5, 10, .1);
}

.quote-container { min-height: 97px; }
.blockquote {
  margin: 0;
  & .q { font-family: 'Roboto Slab', serif; color: var(--lightTextClr); }
  & .avatar {
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    margin: 8px 8px 8px 0;
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: rgba(var(--canvasClr), .95);
}

/* CARDS */
.card {
  position: relative;
  width: 100%;
  background-color: rgb(var(--cardClr));
  color: var(--mainTextClr);
  padding: var(--pad);
  margin: 0 auto 14px auto;
  border-radius: var(--radius);
  box-shadow: 0 1px 3px rgba(var(--blackClr), .2);
  animation: fadeIn .4s ease-out backwards;
  z-index: 7;
  & ::-webkit-scrollbar-thumb { background-color: var(--cardDarkBg); }
  & ::-webkit-scrollbar-thumb:hover { background-color: var(--cardDarkerBg); }
  /* &.main { z-index: 10; } */
  &.sm { --pad: 14px; }
  &.xs { --pad: 7px; }
  &.flat {
    --mainTextClr: rgba(var(--contrastedTextClr), .7);
    --cardClr: var(--canvasClr);

    background-color: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    & > .title { color: rgb(var(--primaryClr)); }
    & .avatar { background-color: #BDBDBD; box-shadow: none; }
  }
  & h1, & h2, & h3, & h4, & h5, & h6 { color: var(--mainTextClr); }
  & > h1, & h2, & > h3, & > h4, & > h5, & > h6 { margin-top: 0; }
  & .btn.icon.absolute-content {
    top: var(--pad);
    border: none;
    line-height: var(--btnHeight);
    &.left {
      left: calc(var(--btnHeight) * -1);
      right: initial;
    }
  }
  & .edit-info {
    position: absolute;
    top: var(--pad);
    right: var(--pad);
    height: var(--btnHeight);
    line-height: var(--btnHeight);
    width: var(--btnHeight);
    text-align: center;
    color: var(--lightTextClr);
    cursor: default;
    &:hover .show-on-hover { transform: scaleX(1); opacity: .98; }
    &:active { transform: none; }
    & .show-on-hover {
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      max-width: none;
      overflow: visible;
      left: initial;
      background-color: rgb(var(--cardClr));
      padding: 0 .8rem;
      color: var(--mainTextClr);
      box-shadow: 0 1px 3px rgba(var(--blackClr), .1);
      text-transform: none;
      font-weight: 300;
      border-radius: 2px;
      transform: scaleX(0);
      transform-origin: right;
    }
  }
  & .details-code {
    background-color: rgb(var(--mainClr));
    text-align: left;
    font-family: monospace;
    word-break: break-all;
    width: auto;
    margin: 0;
    box-shadow: none;
  }
  & .empty { color: var(--lightTextClr); }
}

.card, .dialog, .tab {
  & .head.nav {
    background-color: rgb(var(--cardClr));
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }
  & .footer {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-1 * var(--pad));
    margin-left: calc(-1 * var(--pad));
    margin-bottom: calc(-1 * var(--pad));
    padding: var(--pad);
    &.no-gutter {
      padding: 0;
      overflow: hidden;
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
}
.dialog-footer.no-gutter {
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.card-container {
  margin: auto;
  max-width: calc(100% - 30px);
  text-align: center;
  & .card { max-width: 340px; }
  & .form-group { text-align: left; }
  & .sub-footer {
    font-size: .8rem;

    &:not(a) {
      color: var(--mainTextClr);
    }
  }
}

/* TABS */
.appbar {
  & sup {
    display: inline-block;
    align-self: center;
    background: rgb(var(--accentClr));
    font-size: .45rem;
    color: #333;
    text-transform: uppercase;
    line-height: 1;
    padding: 2px 4px;
    border-radius: 9px;
    margin-left: -4px;
  }
  & .flat {
    &:active, &.active {
      background-color: rgba(var(--blackClr), .03);
      color: rgb(var(--accentClr));
    }
  }
}
.appbar.flat, .card.flat .head.nav {
  --cardClr: var(--canvasClr);

  background-color: transparent;
  box-shadow: none;
}
.tabs-container {
  user-select: none;
  padding: 0;
  & [data-swipeable][aria-hidden='true'] { height: 0; }
  & .card.tab { margin-top: 0; }
  &.card .card.tab {
    margin-bottom: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: inherit;
    & .loader { background-color: transparent; }
  }
}

/* DIALOG */
.dialog {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 480px;
  min-height: 150px;
  height: fit-content;
  max-width: calc(100% - 28px);
  max-height: calc(100% - 28px);
  background-color: rgba(var(--cardClr), .9);
  backdrop-filter: saturate(180%) blur(10px);
  padding: var(--pad);
  border-radius: var(--radius);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 1px 3px rgba(var(--blackClr), .1), 0 10px 50px 10px rgba(var(--blackClr), .15);
  will-change: opacity, transform;
  transition: all .3s ease-in-out;
  transform-origin: top center;
  transform: translateY(-50%);
  animation: showDialog .2s ease-in-out;
  z-index: 10;
  &:hover .head.nav, & .sticky-content .head.nav {
    opacity: .96;
    transform: translateY(0);
  }
  & .sticky-content {
    position: sticky;
    top: 0;
    backdrop-filter: blur(3px);
    z-index: 1;
  }
  & .head.nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 7px;
    transform: translateY(-50%);
    opacity: 0;
    box-shadow: 0 1px 3px rgba(var(--blackClr), .15);
    will-change: opacity, transform;
    transition: all .2s ease-in-out;
    z-index: 3;
    & .title {
      position: relative;
      color: var(--lighterTextClr);
      font-weight: 300;
      font-size: .8rem;
      line-height: 36px;
      padding-left: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 3;
    }
  }
}

.dropdown-menu {
  position: relative;
  & .absolute-top-right {
    --pad: 10px;

    position: absolute;
    top: var(--pad);
    right: var(--pad);
  }
  & .contacts-tab {
    width: 100%;
    max-height: 390px;
    padding: 0;
    & .avatar-row {
      margin-bottom: 0;
      padding-top: .4rem;
      padding-bottom: .4rem;
      border-bottom: 1px solid rgba(var(--blackClr), .1);
      &:last-child { border-bottom: 0; }
      & .avatar { margin: 0; }
    }
  }
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(var(--canvasClr), .8); /* rgba(var(--blackClr), 0.54); */
  -webkit-tap-highlight-color: rgba(var(--canvasClr), 0);
  opacity: 1;
  will-change: opacity;
  transition: all .4s ease-in-out;
  animation: fadeIn .4s ease-in-out;
  z-index: 9;
}

/* BUTTONS */
button {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none !important;
  user-select: none;
  cursor: pointer;
}
.btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  outline: none !important;
  padding: 0 var(--btnPad);
  margin: 3px auto;
  height: var(--btnHeight);
  line-height: var(--btnHeight);
  min-width: var(--btnHeight);
  border: 0;
  border-radius: var(--radius);
  background-color: rgb(var(--primaryClr));
  box-shadow: 0 1px 2px rgba(var(--blackClr), .1);
  font-family: Roboto, sans-serif;
  color: rgba(var(--whiteClr), .9);
  font-size: var(--btnFontSize);
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  transition: all .2s ease-in-out 0s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 4px rgba(var(--blackClr), .15);
    color: white;
  }
  &:active, &.active { transform: scale(.98)/*  translateY(1px) */; }
  &.selected { opacity: .9; }
  &:active, &.active, &.selected {
    box-shadow: 0 0 0 transparent;
    color: rgba(var(--blackClr), .3);
  }
  &:not(.centered) + .btn:not(.centered) { margin-left: 6px; }
  &.flat, &.flat.xs, &.flat.sm, &.flat.lg {
    background-color: transparent;
    box-shadow: none;
  }
  &.flat {
    color: rgb(var(--linkClr));
    &:hover { background-color: rgba(var(--blackClr), .05); }
    &:active, &.active { background-color: rgba(var(--blackClr), .03); }
    &:active { background-color: rgba(var(--blackClr), .07); }
  }
  &.icon {
    padding-left: 0;
    padding-right: 0;
    min-width: var(--btnHeight);
  }
  &.loading svg {
    will-change: transform;
    animation: rotate .3s infinite;
  }

  /* COLORED */
  &.primary {
    background-color: rgb(var(--primaryClr));
    &:hover { background-color: rgb(var(--primaryLightClr)); }
    &:active, &.selected { background-color: rgb(var(--primaryDarkClr)); }
  }
  &.secondary {
    background-color: rgb(var(--accentClr));
    &:hover { background-color: rgb(var(--accentLightClr)); }
    &:active, &.selected { background-color: rgb(var(--accentDarkClr)); }
  }
  &.green {
    background-color: rgb(var(--greenClr));
    &:hover { background-color: rgb(var(--greenLightClr)); }
    &:active, &.selected { background-color: var(--greenDarkClr); }
  }
  &.red {
    background-color: rgb(var(--redClr));
    &:hover { background-color: rgb(var(--redLightClr)); }
    &:active, &.selected { background-color: rgb(var(--redDarkClr)); }
  }
  &.orange {
    background-color: rgb(var(--orangeClr));
    &:hover { background-color: rgb(var(--orangeLightClr)); }
    &:active, &.selected { background-color: rgb(var(--orangeDarkClr)); }
  }
  &.purple {
    background-color: rgb(var(--purpleClr));
    &:hover { background-color: rgb(var(--purpleLightClr)); }
    &:active, &.selected { background-color: rgb(var(--purpleDarkClr)); }
  }
  &.deeppurple {
    background-color: rgb(var(--deepPurpleClr));
    &:hover { background-color: rgb(var(--deepPurpleLightClr)); }
    &:active, &.selected { background-color: rgb(var(--deepPurpleDarkClr)); }
  }

  /* SEMANTIC */
  &.neutral {
    background-color: rgba(var(--blackClr), .1);
    color: rgba(var(--blackClr), .4);
  }
  &.clear {
    background-color: rgb(var(--cardClr));
    color: rgb(var(--linkClr));
    border: 1px solid rgba(var(--blackClr), .07);
    &:hover { background-color: rgba(var(--primaryClr), .05); }
    &:active, &.active {
      background-color: rgba(var(--blackClr), .03);
      color: rgba(var(--blackClr), .3);
    }
  }
  &.success {
    &, &:hover, &:active, &:disabled {
      background-color: rgb(var(--successClr));
      color: white;
    }
  }
  &.error, &.error:hover, &.error:active, &.error:disabled, &.error-on-hover:hover {
    background-color: rgb(var(--errorClr));
    color: white;
  }

  /* SOCIAL */
  &.facebook { background-color: #39569C; }
  &.twitter { background-color: #00ACED; }
  &.google {
    background-color: #F0F0F0;
    background-image: url('../images/g-logo.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 11px center;
    padding-left: 30px;
    color: #AAA;
  }

  & + .counter { margin-left: 14px; }
  & a, &:active a { color: inherit; }
  &.dot {
    display: inline-block;
    font-size: 0;
    color: transparent;
    padding: 0;
    margin: auto 3px;
  }
}

.btn, .ripple {
  &::before {
    --size: 240px;

    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--size);
    height: var(--size);
    margin-left: calc(var(--size) / 2 * -1);
    margin-top: calc(var(--size) / 2 * -1);
    background: rgba(var(--blackClr), .2);
    border-radius: 100%;
    opacity: .6;
    will-change: transform, opacity;
    transform: scale(0);
    visibility: hidden;
  }
  &:not(:active)::before { animation: ripple .4s ease-out; }
  &:focus::before { visibility: visible; }
}

.btn, .link {
  &.centered { display: block; }
}
.btn-footer {
  flex: 1;
  max-width: 100%;
  margin: 0;
  margin-top: 20px;
  height: 44px;
  line-height: 44px;
  font-size: 1.15rem;
  border-radius: 0;
  &, &:hover, &:active {
    box-shadow: none;
    transform: none;
  }
}
.btn.btn-footer + .btn.btn-footer { margin-left: 0; }

button .btn { display: block; }

.btn.xl, .btns.xl .btn {
  --btnPad: 1.3rem;
  --btnHeight: 52px;
  --btnFontSize: 1.2rem;
}
.btn.lg, .btns.lg .btn {
  --btnPad: 1.1rem;
  --btnHeight: 44px;
  --btnFontSize: 1rem;
}
.btn.sm, .btns.sm .btn {
  --btnPad: .6rem;
  --btnHeight: 26px;
  --btnFontSize: .75rem;

  &:not(.rounded) { --radius: var(--radius-sm); }
}
.btn.xs, .btns.xs .btn {
  --btnPad: .4rem;
  --btnHeight: 20px;
  --btnFontSize: .65rem;

  &:not(.rounded) { --radius: var(--radius-sm); }
}
.btn.rounded, .btns .btn.rounded { --btnPad: 1rem; }
.btn.xl.rounded, .btns.xl .btn.rounded { --btnPad: 1.6rem; }
.btn.lg.rounded, .btns.lg .btn.rounded { --btnPad: 1.4rem; }
.btn.sm.rounded, .btns.sm .btn.rounded { --btnPad: .8rem; }
.btn.xs.rounded, .btns.xs .btn.rounded { --btnPad: .55rem; }

.btn:disabled, .btn:disabled:hover, .btn:disabled:active, .btn.disabled, button:disabled .btn {
  background-color: var(--btnDisabledBg);
  border-color: transparent;
  color: rgba(var(--blackClr), .15);
  box-shadow: none;
  cursor: unset;
  user-select: none;
  transform: none;
  transition: none;
  will-change: none;
  pointer-events: none;
}
.btn.flat:disabled { --btnDisabledBg: transparent; }
button:disabled .btn.audio { animation: audioPulse 1s infinite ease-in-out; }

.btn.sm svg, .btns.sm .btn svg { --iconSize: 15px; }
.btn.xs svg, .btns.xs .btn svg { --iconSize: 14px; }

.btn progress {
  min-width: 90px;
  & + span { display: none; }
}

.btn-share-container {
  display: inline-block;
  outline: none !important;
  cursor: pointer;
  margin: 4px 2px;
}

/* SELECTION */
::selection { background: rgba(var(--primaryClr), .3); color: var(--mainTextClr); text-shadow: none; }
::-moz-selection { background: rgba(var(--primaryClr), .3); color: var(--mainTextClr); text-shadow: none; }
.primary ::selection { background: rgba(var(--accentClr), .3); }
.primary ::-moz-selection { background: rgba(var(--accentClr), .3); }

/* FORM GROUP */
.form-group label + div::before { border-color: rgba(var(--blackClr), .15); }
/* .form-group .chip-input label + div::before { background-color: rgba(var(--blackClr), .15); } */

/* INPUT */
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* SELECT */
.select-field svg { fill: rgba(var(--blackClr), .2); }
.select {
  display: inline-block;
  appearance: none;
  outline: none !important;
  padding: 0, 28px, 0, var(--btnPad);
  margin: 3px auto;
  height: var(--btnHeight);
  line-height: var(--btnHeight);
  border: 0;
  border-radius: 3px;
  background-color: rgba(var(--blackClr), .02);
  box-shadow: inset 0 1px 1px rgba(var(--blackClr), .1), inset 0 0 0 1px rgba(var(--blackClr), .05);
  font-family: Roboto, sans-serif;
  transition: all .2s ease-in-out 0s;
  will-change: transform;
  cursor: pointer;
}

/* UPLOAD */
.upload {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* CONTAINER */
.container {
  margin-top: 14px;
  z-index: 1;
  &.xs, &.sm, &.md {
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 100%;
  }
  &.xs { max-width: 320px; }
  &.sm { max-width: 420px; }
  &.md { max-width: 520px; }
  & .container {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
}

/* PENDING */
.append {
  border-radius: 0 var(--radius) var(--radius) 0;
  border-left-width: 0 !important;
  margin-left: 0 !important;
}
.prepend {
  border-radius: var(--radius) 0 0 var(--radius);
  margin-right: 0 !important;
}
.toppend {
  border-radius: var(--radius) var(--radius) 0 0;
  border-bottom-width: 0;
  margin-bottom: 0 !important;
}
.bottompend {
  border-radius: 0 0 var(--radius) var(--radius);
  margin-top: 0 !important;
}
.pend {
  border-radius: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-left-width: 0 !important;
}
.middlepend {
  border-radius: 0 !important;
  margin: 0 !important;
  border-bottom-width: 0;
}
.middlepend + .middlepend {
  border-left-width: 0;
}
.append.toppend {
  border-radius: 0 var(--radius) 0 0;
}
.prepend.toppend {
  border-radius: var(--radius) 0 0 0;
}
.append.bottompend {
  border-radius: 0 0 var(--radius) 0;
}
.prepend.bottompend {
  border-radius: 0 0 0 var(--radius);
}
.btn.prepend, .btn.pend, .btn.append, .btn.toppend, .btn.bottompend {
  line-height: 18px;
  height: var(--btnHeight);
  margin-top: 0;
  margin-bottom: 0;
}
.column-left.prepend, .column-left.pend, .column-left.append, .column-left.toppend, .column-left.bottompend, .column-right.prepend, .column-right.pend, .column-right.append, .column-right.toppend, .column-right.bottompend {
  margin-left: 0;
  margin-right: 0;
}

.card.toppend + .card {
  border-radius: 0 0 var(--radius) var(--radius);
  margin-top: 0;
}

/* LOADING */
.dialog.loading::after, .card.loading::after, .loader {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 43px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
  background-color: rgba(var(--canvasClr), .8);
  border-radius: var(--radius);
  border-radius: inherit;
  padding: 20px;
  text-align: center;
  z-index: 12;
}

/* NOTIFICATIONS */
.notes {
  & *::-webkit-scrollbar { display: none; }
  & ul {
    padding: 0;
    max-width: 380px;
    min-width: 260px;
  }
  & li {
    animation: fadeInSlideUp .2s cubic-bezier(.21, .61, .35, 1) both;
    cursor: inherit;
    &:not(.footer):hover { background-color: inherit; }
    &, & .footer {
      padding-left: 8px;
      padding-right: 8px;
      height: auto;
    }
    &:not(:last-child) { border-bottom: 1px solid rgba(var(--blackClr), .03); }
    & .row {
      align-items: center;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      line-height: 125%;
    }
    & .image {
      width: 24px;
      border-radius: 99px;
      vertical-align: middle;
      font-size: .7rem;
    }
    & .image.avatar { height: 24px; }
    & .image.book { height: 34px; }
    & .image img { width: inherit; height: inherit; }
    & .icon svg {
      --iconSize: 24px;

      color: var(--lighterTextClr);
    }
  }
  & .text {
    color: var(--mainTextClr);
    font-size: .8rem;
    white-space: normal;
  }
  & .text .icon { margin-right: 10px; }
  & .date {
    color: var(--lighterTextClr);
    font-size: .7rem;
    font-weight: 200;
    text-align: right;
    float: right;
  }
  & .footer { justify-content: center; }
  &:not(.hoverable-items) .MuiTouchRipple-root { display: none; }
}

.card .notes li { padding: 10px 0; }
.dot {
  --dotSize: 8px;

  display: block;
  padding: 0;
  margin: auto;
  border-radius: 99px;
  min-width: 0;
  width: var(--dotSize);
  height: var(--dotSize);
  line-height: var(--dotSize);
  text-align: center;
  transition: all .2s ease-in-out;
}
.badge.dot {
  --dotSize: 16px;

  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  width: var(--dotSize);
  border-radius: 99px;
  background-color: rgb(var(--accentClr));
  color: #333;
  font-size: .55rem;
  will-change: opacity, transform;
  animation: popIn .25s ease-out backwards;
  transition-property: background-color, color, transform;
  & svg {
    width: 11px;
    height: 11px;
  }
}
.notes-btn .dot {
  font-size: 0;
  transform: scale(.45);
}
.notes-btn:hover .dot {
  font-size: .6rem;
  transform: scale(1);
}

/* randomquote */
.randomquote {
  --bookWidth: 40px;
  --coverWidth: 40px;
  --coverHeight: 62px;

  & .cover {
    --pad: 7px;

    font-size: 7px;
  }
  & + .bio { margin-top: 10px; }
}
.bio .randomquote { margin-top: 30px; }

.placeholder { margin: auto; }

.badge, mark {
  display: inline-block;
  border-radius: var(--radius-sm);
  vertical-align: middle;
  &.accent { --bg: rgb(var(--accentClr)); }
  &.primary { --bg: rgb(var(--primaryClr)); }
  &.green { --bg: rgb(var(--greenClr)); }
  &.red { --bg: rgb(var(--redClr)); }
  &.sm {
    font-size: .53rem;
    padding: 2px .4rem;
  }
}
.badge {
  --bg: rgba(var(--whiteClr), .04);

  font-weight: 400;
  font-size: .7rem;
  text-transform: uppercase;
  background-color: var(--bg);
  margin: 3px 6px 3px 0;
  padding: 3px .5rem;
  user-select: none;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  transition-property: background-color;
  &:not(a) { color: var(--mainTextClr); }
  &.admin { color: var(--lightTextClr); }
  &.premium { color: rgb(var(--accentClr)); }
}
mark {
  --radius: 99px;

  margin: 0 .1rem;
  background-color: rgb(var(--accentClr));
  color: #333;
  text-transform: none;
  padding: 0 3px;
  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }
}
.text-left .badge { margin: 3px 6px 3px 0; }
.role-badges .badge {
  display: block;
  float: left;
}

.counter {
  &:not(.btn) { line-height: 2; }
  &:empty { display: none; }
  &::after {
    content: '|';
    display: inline-block;
    margin: 0 10px;
    color: var(--lighterTextClr);
    font-weight: 200;
  }
  & b { font-weight: 500; }
}

.counter.comma .counter::after {
  content: ',';
  display: inline-block;
  margin: 0 3px 0 0;
  color: currentcolor;
  white-space: nowrap;
}
/* .counter.comma:not(.strict) .counter:nth-last-child(2)::after {
  content: 'e';
  margin-left: .15rem;
} */
.counter.comma a.counter::after { color: var(--mainTextClr); }
.counter:last-child::after, .counter.comma .counter:last-child::after, .counter.last::after, .counter.btn::after { content: none; }
.counter.last + .counter { margin-left: 6px; }
.counter.btn, .btn-row .btn {
  margin-top: 0;
  margin-bottom: 0;
}

.hoverable-items .book {
  cursor: pointer;
  &:hover .cover {
    box-shadow: rgba(var(--blackClr), .25) 0 3px 8px;
    transform: translateY(-3px);
  }
  &:hover .info .title { color: rgb(var(--contrastedTextClr)); }
  &.empty:hover .cover {
    box-shadow: none;
    transform: none;
    border-color: rgb(var(--primaryClr));
    color: rgb(var(--primaryLightClr));
  }
  &.empty:active .cover {
    border-color: rgba(var(--blackClr), .3);
    transform: scale(.95);
  }
  &:active .cover {
    transition-duration: .15s;
    transform: scale(.95) translateY(0);
    box-shadow: none;
  }
}

/* SHELF */
.shelf + .shelf { margin-top: 20px; }
.shelf-row {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(var(--booksPerRow-lg), 1fr);
  grid-gap: 20px 8px;
  padding-top: 4px;
}
.shelf.collection .info-row {
  line-height: 26px;
  margin-bottom: .5rem;
  &.empty { margin-bottom: 0; text-align: center; }
}
.shelf.scrollable {
  position: relative;
  overflow: hidden;
  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    pointer-events: none;
    width: var(--pad);
    z-index: 10;
  }
  &::before {
    left: 0;
    background: linear-gradient(to left, transparent 0, rgb(var(--cardClr)) 90%);
  }
  &::after {
    right: 0;
    background: linear-gradient(to right, transparent 0, rgb(var(--cardClr)) 90%);
  }
  & .info-row {
    position: relative;
    z-index: 11;
  }
}
.scrollable {
  & ::-webkit-scrollbar {
    display: none;
    width: 0;
    background: 0 0;
  }
  & .shelf-row {
    display: block;
    width: 100%;
    width: calc(100% - .1px);
    max-width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: auto hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 var(--pad);
    & a, & .skltn {
      display: inline-block;
      width: calc(var(--bookWidth) + 5px);
      margin-left: 4px;
      margin-right: 4px;
      scroll-snap-align: start;
      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
    }
  }
}
.card .shelf.scrollable {
  margin-right: calc(-1 * var(--pad));
  margin-left: calc(-1 * var(--pad));
}
.card .shelf.scrollable .info-row {
  margin-right: var(--pad);
  margin-left: var(--pad);
}
.card .scrollable .shelf-row a:first-child, .card .scrollable .shelf-row .skltn:first-child { margin-left: var(--pad); }
.card .scrollable .shelf-row a:last-child, .card .scrollable .shelf-row .skltn:last-child { margin-right: var(--pad); }

.btn.desc svg, .btn.asc svg {
  transition: all .2s ease-in-out;
  will-change: transform;
}
.btn.desc svg { transform: rotate(0deg); }
.btn.asc svg { transform: rotate(180deg); }

.head.nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 7px 14px;
  line-height: 26px;
  font-weight: 300;
  font-size: .75rem;
  text-transform: uppercase;
  color: var(--lightTextClr);
  transform-origin: top center;
  transition: all .2s ease-in-out;
  z-index: 11;
  & .title { font-weight: 400; }
  & + .shelf-row, & + .shelf, & + .table { margin-top: 24px; }
  & .info-row { margin-bottom: 0; }
  & .counter, & .count { line-height: inherit; /* vertical-align: middle; */ }
  & .count { color: var(--lighterTextClr); }
  & button.btn { display: inline; }
}
.absolute-content .head.nav { position: sticky; }

.not-read .book {
  &:hover .cover .overlay {
    background-color: rgba(var(--primaryClr), .5);
    &::before { opacity: 0; }
  }
  & .cover .overlay {
    background: none;
    background-color: rgba(var(--primaryClr), .8);
    opacity: 1;
    overflow: hidden;
    transition: all .2s ease-out;
    &::before {
      content: 'LEGGI';
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      background-color: rgb(var(--canvasLighterClr));
      color: var(--lightTextClr);
      font-weight: 200;
      font-size: 10px;
      border-radius: 33px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      opacity: .94;
      transition: all .2s ease-in-out;
      will-change: opacity;
    }
  }
}

.audio-format .book {
  &:hover .overlay { box-shadow: inset 0 3px 8px rgba(var(--blackClr), .25), 0 0 0 15px rgba(var(--blackClr), .2); }
  &:active .overlay { box-shadow: 0 0 0 15px rgba(var(--blackClr), .2); }
  & .cover {
    /* width: var(--coverHeight); */
    height: var(--coverWidth);
    text-align: center;
    & .overlay {
      display: none;
    }
  }
}

.shelf-row.stacked {
  grid-template-columns: 1fr;
  &.books-per-row-4 { grid-template-columns: 1fr 1fr 1fr 1fr; }
  &.books-per-row-3 { grid-template-columns: 1fr 1fr 1fr; }
  &.books-per-row-2 { grid-template-columns: 1fr 1fr; }
  &.books-per-row-1 { grid-template-columns: 1fr; }
  & .book {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 15px;
    align-items: left;
  }
  & .cover, & .info { float: left; }
  & .cover {
    --pad: 11px;

    width: calc(.66 * var(--coverWidth));
    height: calc(.66 * var(--coverHeight));
    font-size: 7px;
    & .subtitle { display: none; }
  }
  & .info, & .rating {
    text-align: left;
    width: 100%;
  }
  & .info .title {
    font-size: .9rem;
    max-height: 64px;
  }
  & .info progress { margin-left: 0; }
  & .title, & .subtitle, & .author, & .publisher { white-space: normal; }
}

.react-autosuggest__suggestions-container { /* WARNING */
  position: absolute;
  width: 100%;
  z-index: 2;
}

#addBookComponent ul, .react-autosuggest__suggestions-list { /* WARNING */
  list-style: none;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 0;
  max-height: calc(100vh - 220px);
  overflow: auto;
}

.menuitem-book {
  --mainTextClr: #666;
  --lightTextClr: #AAA;

  padding: 5px 0;
}
.menuitem-book .primaryText {
  flex: 1;
  line-height: 50px;
  font-size: .95rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all .2s ease-in-out;
  color: var(--mainTextClr);
}
.menuitem-book .primaryText strong {
  font-weight: 500;
  color: rgb(var(--primaryClr));
}
.menuitem-book .primaryText .thumbnail {
  float: left;
  width: 25px;
  height: 40px;
  margin: 5px 10px 5px 0;
  vertical-align: middle;
  border-radius: 2px;
  will-change: opacity;
  animation: fadeIn .2s ease-in-out both;
}
.menuitem-book .secondaryText {
  line-height: 50px;
  font-size: .8rem;
  font-weight: 300;
  text-align: right;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--lightTextClr);
  transition: all .2s ease-in-out;
}
/* .menuitem-book:hover .secondaryText { max-width: 0; } */
.menuitem-book .btn {
  margin-top: calc((50px - var(--btnHeight)) / 2);
  margin-bottom: calc((50px - var(--btnHeight)) / 2);
}

.thumb {
  /* &.false:not(:hover):not(:active) { color: var(--lightTextClr); } */
  &.up.true {
    color: rgb(var(--linkClr));
    &:hover { color: var(--lightTextClr); }
  }
  &.down.true {
    color: rgb(var(--errorClr));
    &:hover { color: var(--lightTextClr); }
  }
}
.user-review .review .foot .btn:disabled { color: var(--lightTextClr); }
/* .own.review {
  background-image: linear-gradient(to bottom, rgba(var(--primaryClr), .1) 0%, transparent 70%);
  border-radius: 2px;
  padding: 14px var(--pad);
  margin: -14px calc(var(--pad) * -1);
}
.review + .own.review { margin-top: 14px; } */
.own.review .head .badge {
  padding: 0;
  background-color: rgb(var(--primaryClr));
  width: 6px;
  height: 6px;
  color: transparent;
  margin-left: 8px;
}

/* CIRCULAR PROGRESS BAR */
.progress-container {
  position: relative;
  height: 60px;
  width: 60px;
  margin: auto;
  margin-bottom: 8px;
  & svg {
    transition: all .3s ease-in-out;
  }
  & .progress-base, & .progress-value {
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.progress-container .progress-base, .circle-icon {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  border: 5px solid rgba(var(--blackClr), .1);
  margin: auto;
}
.circle-icon { padding: 12px; }

/* STEPPER PROGRESS */
.stepper-container {
  &.form-control { margin-top: 16px; }
  & label {
    display: block;
    color: rgba(var(--blackClr), .54);
    padding: 0;
    font-size: .75rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    text-align: center;
    height: 18px;
    height: calc(50% - 3px);
  }
}
progress {
  position: relative;
  width: 100%;
  margin: auto;
  border: none;
  /* vertical-align: baseline; */
  appearance: none;
  overflow: hidden;
  display: block;
  height: 6px;
  border-radius: 99px;
  background: rgba(var(--blackClr), .1);
  &::-webkit-progress-bar {
    border-radius: 99px;
    background: transparent;
  }
  &::-webkit-progress-value {
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px;
    background: rgb(var(--primaryClr));
    transition: width .2s ease-out;
  }
  &::-moz-progress-bar {
    border-radius: 99px;
    background: rgb(var(--primaryClr));
  }
  &[value='100']::-webkit-progress-bar {
    background-color: rgb(var(--successClr));
    border-radius: 99px;
  }
  & + label { line-height: 1.5; }
}

/* MESSAGE */
.message {
  font-size: 12px;
  line-height: 1.5;
  color: var(--lightTextClr);
  &.error { color: rgba(var(--errorClr), .9); }
  &.info { color: rgba(var(--primaryClr), .9); }
  &.success { color: rgba(var(--successClr), .9); }
  &.warning { color: rgba(var(--accentClr), .9); }
}
.form-group .message { margin-top: .3rem; }

/* MOCKS */
.mock-cover {
  background-color: rgb(var(--bookClr));
  background-size: cover;
  &.xs { width: 14.4px; height: 20px; border-radius: 1px; }
  &.prepend-input { margin-top: 16px; width: 30px; height: 48px; border-radius: 2px; }
  & img { width: 100%; }
}

/* GENRES */
.badges {
  &.genres + div { margin-top: 1em; }
  &.scrollable { position: relative; }
  &.scrollable .content {
    display: flex;
    width: calc(100% + (var(--pad) * 2) - .1px);
    white-space: nowrap;
    overflow: auto hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 var(--pad);
    margin-left: calc(var(--pad) * -1);
    margin-right: calc(var(--pad) * -1);
    padding-left: var(--pad);
    padding-right: var(--pad);
  }
  &.scrollable::before, &.scrollable::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: var(--pad);
    pointer-events: none;
    animation: fadeIn .4s ease-in-out .2s both;
    z-index: 10;
  }
  &.scrollable::before {
    left: calc(var(--pad) * -1);
    background: linear-gradient(to left, transparent 1%, rgb(var(--cardClr)) 90%);
  }
  &.scrollable::after {
    right: calc(var(--pad) * -1);
    background: linear-gradient(to right, transparent 1%, rgb(var(--cardClr)) 90%);
  }
  &.scrollable .badge {
    padding: .4rem .7rem;
    border-radius: 99px;
  }
  /* &:not(.scrollable) .badge.active { color: rgba(var(--blackClr), .5); } */
  &.stacked .badge { width: 100%; }
  & .content {
    position: relative;
    text-overflow: ellipsis;
  }
  & .badge:hover .show-on-hover { opacity: 1; }
}
.badges .badge:active, .badges .badge.active, #genreComponent .genre .badge:active, #genreComponent .genre .badge.active {
  background-color: transparent;
}
.badge:last-child { margin-right: 0; }
.hoverable-items .badge:hover, a.badge:hover, .scrollable .badge.active { background-color: rgb(var(--canvasLightestClr)); }

/* COLLECTION */
.collection-profile .description {
  text-align: justify;
  white-space: pre-line;
  word-wrap: break-word;
  margin-bottom: .6rem;
}

/* BUBBLES */
.bubble {
  --avatarSize: 80px;
  --pad: 14px;

  min-width: var(--avatarSize);
  margin: 0 auto;
  border-radius: 99px;
  text-align: center;
  font-size: .8rem;
  transition: all .2s ease-in-out;
  will-change: opacity;
  animation: fadeIn .3s ease-out backwards;
  & .avatar {
    margin-left: auto;
    margin-right: auto;
    width: var(--avatarSize);
    height: var(--avatarSize);
    overflow: visible;
    font-size: 2rem;
  }
  & .avatar img { border-radius: inherit; }
  & .title {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    transition: all .2s ease-in-out;
  }
  &.icon {
    background-color: rgba(var(--blackClr), .07);
    width: var(--avatarSize);
    height: var(--avatarSize);
    line-height: var(--avatarSize);
    text-align: center;
    & svg {
      --iconSize: 42px;

      color: rgb(var(--primaryClr));
    }
  }
}

.bubble-group {
  &.inline {
    margin-right: 6px;
    & .avatar { display: inline-flex; }
  }
  & .bubble {
    --avatarSize: 22px;

    position: relative;
    display: inline-block;
    margin-left: -2px;
    vertical-align: middle;
    height: var(--avatarSize);
    box-shadow: 0 0 0 3px rgb(var(--cardClr));
    color: #333;
    animation-delay: .3s;
    z-index: 1;
    &:nth-child(2) { animation-delay: .4s; }
    &:nth-child(3) { animation-delay: .5s; }
    &.empty {
      padding-left: 5px;
      padding-right: 5px;
    }
    & .avatar {
      font-size: .8rem;
      background-color: rgb(var(--canvasLightestClr));
    }
  }
}
.card .bubble-group .bubble { background-color: rgb(var(--cardClr)); }

.hoverable-items {
  &.boxed .bubble:hover { box-shadow: inset 0 0 0 100px rgba(var(--blackClr), .05), 0 0 0 4px rgba(var(--blackClr), .05); }
  & .bubble {
    &:hover .avatar img { transform: scale(1.2); }
    &:active .avatar { transform: scale(.9); }
    & .avatar {
      overflow: hidden;
      transition: all .2s ease-in-out;
      will-change: transform;
    }
    & .avatar img {
      transition: all .3s ease-in-out;
      will-change: transform;
    }
  }
}

.bubbles {
  grid-template-columns: repeat(var(--bubblesPerRow-lg), 1fr);
  &.shelf .bubble {
    --avatarSize: 80px;
    --bookWidth: 108.3px;
    --booksPerRow-lg: 9;
  }
}
.bubbles.boxed {
  grid-gap: 12px;
  & .bubble {
    width: 100%;
    border-radius: 2px;
    min-height: calc(var(--avatarSize) + 38px + (var(--pad) * 2));
    padding: 10px 0;
    & .avatar {
      background-color: rgba(var(--blackClr), .07);
      box-shadow: inset 1px 1px 0 1px rgba(var(--blackClr), .07);
    }
    & .title { white-space: normal; }
  }
}

/* HELPERS */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }
.centered { float: none; margin-left: auto; margin-right: auto; }
.monotype, code, pre { font-family: 'Roboto Mono', 'Courier New', Courier, monospace; }
.light-text { color: var(--lightTextClr); }
.lighter-text { color: var(--lighterTextClr); }
.lightest-text { color: var(--lightestTextClr); }
.primary-text { color: rgb(var(--primaryClr)); }
.accent-text { color: rgb(var(--accentClr)); }
.error-text { color: rgb(var(--errorClr)); }
.success-text { color: rgb(var(--successClr)); }
.text-xxl { font-size: 150%; }
.text-xl { font-size: 125%; }
.text-lg { font-size: 110%; }
.text-sm { font-size: 80%; }
.text-xs { font-size: 70%; }
.text-xxs { font-size: 60%; }
.text-regular { font-weight: 200; }
.overflow-hidden { overflow: hidden; }
.absolute-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &.right { left: initial; }
}
.m-b-negative { margin-bottom: -100%; }

.btn .show-on-hover {
  position: absolute;
  inset: 0;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - var(--btnPad));
}
.btn .show-on-hover, .btn:hover .hide-on-hover { transform: perspective(150px) scaleY(0); }
.btn .hide-on-hover, .btn:hover .show-on-hover { transform: perspective(150px) scaleY(1); display: block; }
.show-on-hover, .hide-on-hover { will-change: opacity, transform; transition: all .2s ease-in-out; }
.show-on-hover, .btn:hover .hide-on-hover, .avatar-row:hover .hide-on-hover, .hoverable-items:hover .hide-on-hover { opacity: 0; }
.hide-on-hover, .btn:hover .show-on-hover, .avatar-row:hover .show-on-hover, .hoverable-items:hover .show-on-hover { opacity: 1; }
.hidden { opacity: 0; }

.nolist {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inline, ul.inline-items li { display: inline; }
.inline-block { display: inline-block; }
.rounded { --radius: 99px; }
.pull-left { float: left; }
.pull-right { float: right; }
.flex { display: flex; }
.align-items-center { align-items: center; }
.pad { padding: var(--pad); }
.pad-v { padding-top: var(--pad); padding-bottom: var(--pad); }
.pad-sm { padding: calc(var(--pad) / 2); }
.pad-xs { padding: calc(var(--pad) / 4); }
.pad-v-sm { padding-top: calc(var(--pad) / 2); padding-bottom: calc(var(--pad) / 2); }
.pad-v-xs { padding-top: calc(var(--pad) / 4); padding-bottom: calc(var(--pad) / 4); }
.pad-lg { padding: calc(var(--pad) * 2); }
.pad-v-lg { padding-top: calc(var(--pad) * 2); padding-bottom: calc(var(--pad) * 2); }

.font-sm { font-size: .78rem; }

code {
  line-height: 2.2;
  background-color: rgba(9, 30, 66, .05);
  border-radius: var(--radius);
  box-shadow: 0 0 0 1px rgba(9, 30, 66, .13);
  color: rgb(var(--primaryClr));
  padding: 1px 3px;
  margin: -1px 2px;
  font-size: .8rem;
}

/* ANIMATIONS */
.reveal {
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(.21, .61, .35, 1);
  animation-fill-mode: both;
}
.fadeIn, .fadeOut { will-change: opacity; }
.slideUp, .slideDown { will-change: transform; }
.fadeIn.slideUp, .fadeIn.slideDown, .popIn { will-change: opacity, transform; }
.fadeIn { animation-name: fadeIn; }
.fadeOut { animation-name: fadeOut; }
.fadeIn.slideUp { animation-name: fadeInSlideUp; }
.fadeIn.slideDown { animation-name: fadeInSlideDown; }
.popIn { animation-name: popIn; }
.shake { animation-name: shake; animation-duration: .8s; }

.delay1 { animation-delay: .1s; }
.delay2 { animation-delay: .2s; }
.delay3 { animation-delay: .3s; }
.delay4 { animation-delay: .4s; }
.delay5 { animation-delay: .5s; }
.delay6 { animation-delay: .6s; }
.delay7 { animation-delay: .7s; }
.delay8 { animation-delay: .8s; }
.delay9 { animation-delay: .9s; }
.delay10 { animation-delay: 1s; }
.delay20 { animation-delay: 2s; }

@keyframes audioPulse { 50% { box-shadow: 0 0 0 2px var(--btnDisabledBg); } }
@keyframes rotate { to { transform: rotate(360deg); } }
@keyframes fadeInSlideUp {
  from { opacity: 0; transform: translateY(25px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes fadeInSlideDown {
  from { opacity: 0; transform: translateY(-25px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes ripple {
  from { transform: scale(0); }
  to { opacity: 0; transform: scale(1); }
}
@keyframes slideDown {
  from { opacity: 0; max-height: 36px; }
  to { opacity: 1; }
}
@keyframes scaleX {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}
@keyframes popIn {
  0% { opacity: 0; transform: scale(0); }
  80% { transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
}
@keyframes showIn {
  from { opacity: 0; transform: scale(.9); }
  to { transform: scale(1); }
}
@keyframes fadeIn { from { opacity: 0; } }
@keyframes fadeOut { to { opacity: 0; } }
@keyframes skltnShelfRow { to { background-position: calc(100% + 150px) 0; } }
@keyframes maxheightIn { to { max-height: 600px; } }
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}
@keyframes showDialog {
  from {
    opacity: 0;
    transform: scale(.5) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}

/* MEDIA QUERIES */
@media (pointer: fine) {
  .btn.asc:hover svg { transform: rotate(0deg); }
  .btn.desc:hover svg { transform: rotate(180deg); }

  .card:not(.primary).review .foot .btn:hover, .thumb.up.false:hover { color: rgb(var(--primaryClr)); }
  .review .foot {
    & .btn:active, & .thumb:active { color: rgba(var(--blackClr), .3); }
  }
  .thumb {
    &.false, &.true:hover { color: var(--lightTextClr); }
    &.down.true, &.down.false:hover { color: rgb(var(--errorClr)); }
    /*
    &.true:hover:not(:disabled) svg { transform: rotateX(180deg); }
    &.false:not(:hover):not(:active), &.true:hover { color: var(--lightTextClr); }
    &.up.true:not(:hover), &.up.false:hover { color: rgb(var(--linkClr)); }
    &.down.true:not(:hover), &.down.false:hover { color: rgb(var(--errorClr)); }
    &.true:hover:not(:disabled) svg { transform: rotateX(180deg); }
    */
  }

  .copy {
    padding: 2px 4px;
    margin-left: -4px;
    margin-right: -4px;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: copy;
    transition: all .2s ease-in-out;
    user-select: none;
    &:hover {
      background-color: rgba(var(--primaryClr), .07);
      border-radius: 2px;
    }
    &:active {
      background-color: rgba(var(--greenClr), .2);
    }
  }

  .tt {
    position: relative;
    overflow: visible;
    &:hover .tip {
      opacity: .95;
      transform: perspective(150px) scale(1) rotateX(0deg);
      box-shadow: 0 1px 4px rgba(0, 10, 15, .15);
    }
    & .tip {
      position: absolute;
      padding: 4px 8px;
      margin: auto;
      bottom: calc(100% + 4px);
      left: -50%;
      right: -50%;
      height: fit-content;
      width: fit-content;
      display: block;
      text-align: center;
      line-height: 140%;
      font-size: .7rem;
      font-weight: 200;
      color: white;
      background-color: rgba(20, 35, 40, .9);
      border-radius: var(--radius);
      user-select: none;
      opacity: 0;
      transform-origin: bottom center;
      transform: perspective(150px) scale(.8) rotateX(-90deg);
      will-change: opacity, transform;
      transition: all .2s ease-in-out;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -4px;
        border-width: 4px;
        border-style: solid;
        border-color: rgba(20, 35, 40, .9) transparent transparent transparent;
        border-top-color: rgba(20, 35, 40, .9);
        pointer-events: none;
      }
    }
  }
  .review .show-on-hover { opacity: 0; will-change: opacity; }
  .review:hover .show-on-hover { opacity: 1; }
  .tabs-container.card .card.tab {
    min-height: 300px;
    max-height: 653px;
  }
  .contacts-tab-col {
    min-height: 240px;
    & h4 {
      position: sticky;
      top: 0;
      background-color: rgba(var(--cardClr), .94);
      backdrop-filter: saturate(180%) blur(5px);
      margin: 0 -7px;
      padding: .5rem 7px;
      z-index: 1;
    }
  }
}

.relative { position: relative; }
.sticky { position: sticky; top: 75px; }
.hide { display: none; }
.hide-always { display: none !important; }

@media (--to-xxl) {
  :root {
    --booksPerRow-lg: 6;
    --bubblesPerRow-lg: 8;
  }
  .shelf-row.stacked {
    &.books-per-row-4 { grid-template-columns: 1fr 1fr 1fr; }
    &.books-per-row-3 { grid-template-columns: 1fr 1fr; }
    &.books-per-row-2 { grid-template-columns: 1fr; }
  }
  .text-center-xl { text-align: center; }
  .hide-xl { display: none; }
}
@media (--to-xl) {
  :root {
    --booksPerRow-lg: 4;
    --bubblesPerRow-lg: 5;
  }
  .book-profile .description { max-height: 600px; }
  .shelf-row.stacked {
    &.books-per-row-4 { grid-template-columns: 1fr 1fr; }
    &.books-per-row-3 { grid-template-columns: 1fr; }
  }
  .text-center-lg { text-align: center; }
  .hide-lg { display: none; }
}
@media (--to-l) {
  :root {
    --booksPerRow-lg: 3;
    --bubblesPerRow-lg: 4;
  }
  .hero .container { padding-top: 65px; }
  .snackbar { --distance: 8px; }
  .book-profile {
    & .title {
      font-size: 1.6rem;
      &.flex { justify-content: center; }
    }
  }
  .shelf-row.stacked.books-per-row-4 { grid-template-columns: 1fr; }
  .basic-profile {
    & .username { font-size: 1.5rem; }
    & .badge {
      border-radius: 9px;
      height: 8px;
      width: 8px;
      text-indent: -300px;
      font-size: 0;
      overflow: hidden;
      padding: 0;
      /* border: 2px solid rgb(var(--canvasClr)); */
      &.editor { background-color: rgb(var(--primaryClr)); }
      &.admin { background-color: var(--lightTextClr); }
      &.premium { background-color: rgb(var(--accentClr)); }
    }
  }
  .appbar.mobile {
    --bgClr: var(--canvasLightClr);
    --bgOpacity: .6;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    background-color: rgba(var(--bgClr), var(--bgOpacity));
    backdrop-filter: saturate(180%) blur(10px);
    background-image: linear-gradient(to bottom, rgba(var(--bgClr), var(--bgOpacity)) 0%, rgba(var(--bgClr), 1) 100%);
    box-shadow: 0 -1px 10px -2px rgba(var(--blackClr), .2);
    animation: fadeInSlideUp .4s cubic-bezier(.21, .61, .35, 1) .4s both;
    & .label {
      display: block;
      height: 0;
      line-height: 16px;
      font-size: .5rem;
      font-weight: 200;
      opacity: 0;
      transition: all .2s ease-in-out;
    }
    & button {
      height: 52px;
      margin-bottom: env(safe-area-inset-bottom);
      &[aria-selected='true'] .label { height: 20px; opacity: .5; }
    }
  }
  .tabs-container.mobile {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }
  .no-sticky-md { position: initial; }
  .text-center-md { text-align: center; }
  .text-center-md .skltn.area { margin-left: auto; }
  .hide-md { display: none; }
}
@media (--to-m) {
  .shelf-row:not(.stacked) {
    --bookWidth: 100px;
    --coverWidth: 94px;
    --coverHeight: 140px;
    --shelf-rowHeight: 183px;
    --skltnTitleY: 150px;
    --skltnAuthorY: 168px;
  }
  .btn.icon-sm {
    padding-left: 0;
    padding-right: 0;
    min-width: var(--btnHeight);
  }
  .card-fullwidth-sm { --pad: 14px; /* --cardClr: var(--canvasClr); border-radius: 0; margin-left: calc(var(--pad) * -1); margin-right: calc(var(--pad) * -1); box-shadow: none; */ }
  /* .card-fullwidth-sm .head.nav { box-shadow: none; } */
  .shelf-row:not(.stacked) .book .react-rater { font-size: 14px; }
  .tabs-container.card .card.tab { min-height: 275px; }
  .book-profile {
    & .title { font-size: 1.2rem; }
    & .subtitle { font-size: 1rem; }
    & .description { max-height: 1300px; }
  }
  .minifiable { max-height: 610px; }
  .head.nav + .shelf-row, .head.nav + .shelf, .head.nav + .table { margin-top: 38px; }
  .review {
    --avatarSize: 24px;
    --bookWidth: 40px;
    --coverWidth: 40px;
    --coverHeight: 62px;

    & .left { padding-left: 0; }
    & .avatar { font-size: .5rem; }
    & .rating {
      font-size: inherit;
      font-weight: inherit;
    }
    & .react-rater {
      font-size: 16px;
      & div:not(:first-child) { display: none; }
    }
  }
  .shelf.bubbles .bubble { --avatarSize: 70px; --bookWidth: 84px; }
  .bubble { --avatarSize: 70px; }
  .no-sticky-sm { position: initial; }
  .text-center-sm { text-align: center; }
  .hide-sm { display: none; }
  ul li.hide-sm { display: none; } /* footer list workaround */
}
@media (--to-s) {
  :root { --bubblesPerRow-lg: 3; }
  .shelf-row:not(.stacked) {
    --bookWidth: 84px;
    --coverWidth: 80px;
    --coverHeight: 120px;
    --shelf-rowHeight: 163px;
    --skltnTitleY: 129px;
    --skltnAuthorY: 147px;
  }
  .text-center-xs { text-align: center; }
  .minifiable { max-height: 755px; }
  .top-bar {
    & .hide-xs { display: none; }
    & .title sup { display: none; }
  }
  .review .head h3 .date { display: none; }
  .book-profile .user.rating { --btnHeight: 44px; }
  .donation-btns .btn {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .hide-xs { display: none; }
}
@media (--to-xs) {
  :root {
    --booksPerRow-lg: 2;
    --bubblesPerRow-lg: 2;
  }
  .minifiable { max-height: 940px; }
  .top-bar {
    & .hide-xxs { display: none; }
  }
  .hide-xxs { display: none; }
}

@media (--from-xxl) { .show-xl { display: none; } }
@media (--from-xl) { .show-lg { display: none; } }
@media (--from-l) { .show-md { display: none; } }
@media (--from-m) { .show-sm { display: none; } }
@media (--from-s) { .show-xs { display: none; } }
