#layoutComponent {
  padding-top: var(--headerHeight);
  flex-grow: 1;
}

/* HEADER */
.top-bar {
  position: fixed !important;
  top: 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(var(--cardClr), .94);
  backdrop-filter: saturate(180%) blur(10px);
  color: rgb(var(--contrastedTextClr));
  box-shadow: 0 1px 10px -2px rgba(var(--blackClr), .25);
  width: 100%;
  box-sizing: border-box;
  z-index: 8;
  & .toolbar { min-height: var(--headerHeight); }
  & .title {
    flex: 1;
    margin-left: 10px;
    & img { height: 30px; }
    & sup {
      display: inline-block;
      align-self: center;
      background: rgb(var(--accentClr));
      font-size: .5rem;
      color: #333;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: .6px;
      padding: 2px 4px;
      border-radius: 9px;
      margin-left: 4px;
      animation: popIn .8s ease-in-out .2s;
      animation-fill-mode: both;
    }
  }
  & svg.svg-icon { --iconSize: 24px; }
  & button .dot { box-shadow: 0 0 0 3px rgb(var(--cardClr)); }
  & .more-btn {
    margin-right: -12px;
    padding: 7px;
  }
  & .drawer-btn { margin-left: -12px; }
  & a {
    display: flex;
    &.active {
      background-color: rgba(var(--blackClr), .05);
    }
  }
  & a,
  & button,
  & .more-btn,
  & .drawer-btn {
    color: currentcolor;
    &:not(.primary),
    &:not(.secondary) {
      &:hover,
      &.active:hover {
        color: rgb(var(--primaryClr));
      }
    }
  }
  & .reveal { animation-duration: .3s; }
  & .avatar {
    --avatarSize: 34px;

    width: var(--avatarSize);
    height: var(--avatarSize);
    font-size: 1rem;
  }
  & .search-book {
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 100%;
    z-index: 1;
  }
}

/* DRAWER */
.auth-header {
  position: relative;
  display: block;
  padding: 16px;
  background-color: rgb(var(--primaryClr));
  color: #FFF !important;
  overflow: hidden;
  & .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transform: scale(1.3);
    filter: blur(3px);
    opacity: .3;
  }
  & .avatar {
    margin-top: 10px;
    & img {
      position: relative;
      vertical-align: middle;
    }
  }
  & .user-info {
    position: relative;
    margin-top: 10px;
    & div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  & .user-name { font-size: 1.15rem; }
  & .user-email {
    font-size: .75rem;
    font-weight: 200;
    color: rgba(255, 255, 255, .7);
  }
}

/* COOKIE BANNER */
.react-cookie-banner {
  --distance: 24px;

  position: fixed;
  bottom: var(--distance);
  right: var(--distance);
  display: block;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 8px 6px 16px;
  width: fit-content;
  max-width: calc(100% - (var(--distance) * 2));
  border-radius: 4px;
  color: rgba(var(--whiteClr), .8);
  background-color: rgba(var(--canvasLightClr), .96);
  box-shadow: 0 3px 5px -1px rgba(var(--blackClr), .2), 0 6px 10px 0 rgba(var(--blackClr), .14), 0 1px 18px 0 rgba(var(--blackClr), .12);
  animation: fadeInSlideUp .3s cubic-bezier(.21, .61, .35, 1) both .5s;
  z-index: 1000;
  & .cookie-message {
    display: block; padding: 8px 0;
  }
  & .button-close {
    -webkit-appearance: none;
    line-height: 32px;
    padding: 0 12px;
    margin-left: 16px;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    font-size: .87rem;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;
    &:hover { background-color: rgba(var(--blackClr), .07); }
  }
}

/* DRAWER */
.drawer .list {
  width: 256px;
  & li div {
    margin: 0;
    padding: 0;
    min-width: 36px;
  }
  & li div, & .svg-icon, & li div span { color: inherit; }
  & li .version {
    font-size: .75rem;
    font-weight: 200;
    color: var(--lighterTextClr);
  }
  & .bottom-item {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    &:hover {
      background-color: initial;
      cursor: initial;
    }
  }
}
