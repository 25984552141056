.book-recommendation.dialog {
  max-height: 390px;
  overflow-x: hidden;
  padding: 0;
  & .head.nav {
    padding-left: var(--pad);
    padding-right: var(--pad);
  }
  & .content {
    padding: 0 var(--pad) calc(var(--pad) / 2);
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .contacts-tab {
    width: 100%;
  }
}
