@import url('media-queries.css');

.medal {
  color: #333;
  user-select: none;
  transition: all .2s ease-in-out;
  z-index: 1;
  &.accent { color: rgb(var(--accentClr)); }
  & svg.svg-icon {
    --iconSize: 20px;

    margin: 0;
  }
}

.bookmark {
  height: 16px;
  line-height: 16px;
  width: 16px;
  border-left-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 4px;
  border-style: solid;
  border-color: rgb(var(--primaryDarkClr));
  border-bottom-color: transparent !important;
  border-radius: 1px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  color: #333;
  text-indent: -3px;
  overflow: visible;
  user-select: none;
  transition: all .2s ease-in-out;
  z-index: 1;
  &.accent {
    border-color: rgb(var(--accentClr));
    height: 24px;
  }
  & div {
    position: absolute;
    left: -10px;
    width: 24px;
    overflow: hidden;
  }
}

.cover {
  --pad: 14px;
  --radius: 4px;

  position: relative;
  width: var(--coverWidth);
  height: var(--coverHeight);
  background-color: rgb(var(--bookClr));
  background-size: cover;
  background-position: top center;
  box-shadow: 0 2px 3px rgba(var(--blackClr), .12);
  /* overflow: hidden; */
  border-radius: var(--radius);
  color: rgba(var(--blackClr), .6);
  font-size: 9px;
  text-align: left;
  line-height: 1.35;
  padding: var(--pad);
  margin: auto;
  transition: all .2s ease-in-out;
  will-change: opacity;
  animation: fadeIn .4s ease-in-out backwards;
  & .loader {
    backdrop-filter: blur(6px);
    & svg { margin: auto; }
  }
  & .overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: .8;
    border-radius: inherit;
    background: linear-gradient(to right, transparent 0%, rgba(var(--whiteClr), .02) 1%, rgba(var(--blackClr), .06) 4%, rgba(var(--whiteClr), .09) 7%, rgba(var(--whiteClr), .2) 18%, rgba(var(--whiteClr), .2) 28%, transparent 100%);
  }
  & .title, & .subtitle, & .author, & .publisher {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box !important;
    -webkit-line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    float: left;
    width: 100%;
  }
  & .title, & .subtitle {
    --max-lines: 4;

    max-height: 65px;
    margin: 0;
    margin-bottom: .1rem;
    font-weight: 500;
    font-size: 120%;
  }
  & .title {
    font-size: 142%;
    font-weight: 400;
    margin-bottom: .4rem;
  }
  & .author {
    --max-lines: 2;

    font-size: 110%;
    font-weight: 500;
  }
  & .publisher {
    --max-lines: 1;

    position: absolute;
    width: auto;
    left: var(--pad);
    right: var(--pad);
    bottom: var(--pad);
  }
  & .svg-icon {
    --iconSize: 10px;

    margin-left: 3px;
  }
  & .cover-review, & .readers-num {
    position: absolute;
    bottom: -6px;
    right: -8px;
    display: inline-block;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: rgba(var(--bookClr), .8);
    backdrop-filter: saturate(180%) blur(6px);
    color: var(--mainTextClr);
    border-radius: 9px;
    padding: .16rem .54rem;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 1px 1px rgba(var(--blackClr), .1);
    transition: all .25s ease-in-out;
    z-index: 2;
  }
  & .readers-num {
    background-color: rgba(var(--canvasLighterClr), .8);
    backdrop-filter: saturate(180%) blur(6px);
    color: var(--mainTextClr);
    font-weight: 200;
    will-change: opacity, transform;
  }
  & .medal {
    position: absolute;
    top: -2px; left: 7px;
  }
  & .bookmark {
    position: absolute;
    top: -1px; right: 9px;
  }
  &:hover .bookmark {
    height: 10px;
    color: transparent;
  }
}

.book {
  display: grid;
  justify-items: center;
  min-width: var(--bookWidth);
  &:hover .readers-num {
    opacity: 0;
    transform: translateY(5px);
  }
  &.empty .cover {
    box-shadow: none;
    background-color: transparent;
    text-align: center;
    display: grid;
    align-items: center;
    color: rgba(var(--blackClr), .15);
    border: 3px dashed rgba(var(--blackClr), .15);
    & .add { font-size: 4rem; }
  }
  & .info {
    text-align: center;
    font-size: .8rem;
    width: var(--bookWidth);
    & .title, & .author {
      display: block;
      margin: auto;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .title {
      margin-top: 8px;
      font-weight: 400;
      color: var(--mainTextClr);
      transition: color .3s ease-in-out;
    }
    & .subtitle {
      font-weight: 400;
      font-size: .8rem;
      color: var(--mainTextClr);
    }
    & .author {
      font-size: .7rem;
      font-weight: 300;
      color: var(--lightTextClr);
    }
    & .publisher {
      font-weight: 300;
      font-size: .7rem;
      color: var(--mainTextClr);
    }
    & progress {
      margin-top: 11px;
      width: 80%;
      max-width: 96px;
      will-change: opacity;
      animation: fadeIn .4s ease-in-out;
    }
    & progress .bar {
      transform-origin: left;
      will-change: transform;
      animation: scaleX .25s ease-in-out;
    }
  }
}

.edit-book-cover.error .cover { background-color: rgb(var(--errorClr)); }

.dark .cover .overlay { opacity: .2; }

@media (--to-m) {
  .cover {
    --pad: 10px;

    font-size: 7px;
    & .title { font-weight: 300; }
    & .subtitle { display: none; }
  }
}
