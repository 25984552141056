#bookContainerComponent {
  & .content-background {
    --stickyBgHeight: 350px;

    position: relative;
    width: 100%;
    height: var(--stickyBgHeight);
    top: calc(var(--headerHeight) * -1);
    margin-bottom: calc(var(--stickyBgHeight) * -1);
    overflow: hidden;
    background: rgb(var(--mainClr));
    z-index: -1;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: linear-gradient(to bottom, transparent 0%, rgb(var(--mainClr)) 95%);
      opacity: 1;
      will-change: opacity;
      transition: all .4s ease-in-out;
      z-index: 1;
    }
    & .bg {
      width: 100%;
      height: var(--stickyBgHeight);
      background-size: cover;
      background-position: center;
      filter: blur(8px);
      transform: scale(1.3) translateY(10px);
      transition: background .4s ease-in-out;
      opacity: .7;
      top: 0;
    }
  }

  & .container.top {
    margin-top: 60px;
    & .book { margin-top: -60px; }
  }
}
