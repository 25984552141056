.social-auth {
  & > [class*='col-'] {
    padding-left: 4px;
    padding-right: 4px;
    &:first-child { padding-left: 7px; }
    &:last-child { padding-right: 7px; }
  }

  & button {
    float: left;
    width: 100%;
    margin: 3px 0;
    text-transform: initial;
    font-size: .85rem;
    letter-spacing: .03rem;
    &:disabled {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      cursor: not-allowed;
      opacity: .7;
    }
  }
}
