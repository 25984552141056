.chart-container {
  margin-bottom: 8px;
}

.stats-list {
  & li {
    line-height: 1.9;
    & b {
      display: inline-block;
      min-width: 18px;
    }
  }
}
