@import url('media-queries.css');

.basic-profile {
  & .username {
    display: flex;
    font-size: 1.8rem;
    line-height: var(--avatarSize);
    min-height: var(--avatarSize);
    margin-top: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .email { color: var(--lightTextClr); }
  & .absolute-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  & .avatar:not(img) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: var(--avatarSize);
    height: var(--avatarSize);
    transition: all .2s ease-in-out;
    & .thumb {
      width: 100%;
      min-height: 100%;
      border-radius: 99px;
    }
  }
  & .col-right {
    max-width: calc(100% - 16px - var(--avatarSize));
  }
}

.contacts-tab-col {
  overflow: auto;
  &::before, &::after {
    --faderHeight: 14px;

    content: '';
    display: block;
    position: sticky;
    left: 0;
    width: 100%;
    height: var(--faderHeight);
    transition: all 4s ease-in-out;
    pointer-events: none;
    z-index: 1;
  }
  &::before {
    top: 0;
    background: linear-gradient(to top, transparent 0%, rgb(var(--cardClr)) 90%);
    margin-bottom: calc(-1 * var(--faderHeight));
  }
  &::after {
    top: calc(100% - var(--faderHeight));
    bottom: -1px;
    background: linear-gradient(to bottom, transparent 0%, rgb(var(--cardClr)) 90%);
    margin-top: calc(-1 * var(--faderHeight));
  }
}
.contacts-tab .avatar-row {
  margin-bottom: 0;
  padding-top: .4rem;
  padding-bottom: .4rem;
  border-bottom-width: 1px;
  &:last-child { border-bottom: 0; }
  & .avatar {
    float: left;
    margin-right: 14px;
    will-change: opacity;
    animation: fadeIn .3s ease-in-out;
  }
  & .btn {
    position: relative;
    margin-left: 2px;
    margin-right: 2px;
  }
  & .timestamp {
    position: absolute;
    right: 14px;
    color: var(--lightTextClr);
    font-size: 85%;
  }
}

@media (--from-l) {
  .contacts-tab-col {
    max-height: calc(100vh - 450px);
    &:first-child { padding-right: var(--pad); }
    &:last-child { padding-left: var(--pad); }
  }
}
