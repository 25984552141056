.hero {
  position: relative;
  background-position: left top;
  background-repeat: repeat-x;
  & .container {
    position: relative;
    padding-top: 80px;
    padding-bottom: 120px;
    margin-top: 0;
    z-index: 2;
  }
  & .title {
    margin: 0;
    margin-bottom: .2em;
    color: rgb(var(--accentClr));
    font-size: 2.4rem;
    line-height: 1.3;
    font-weight: 500;
  }
  & .subtitle {
    font-size: 1.17rem;
    margin-bottom: 1.3em;
    font-weight: 300;
  }
  & .overlay {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(var(--mainClr), .7) 0%, rgb(var(--mainClr)) 75%);
    animation: none;
    z-index: 1;
  }
  & + .container { margin-top: -56px; }
}

.value-props {
  margin-bottom: 14px;
  & h3 { margin-top: 0; }
}
